import React from 'react';

export default function Footer(){
    return(
            <footer class="bg-white m-4">
                <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="#home" class="flex items-center mb-4 sm:mb-0">
                            <img src="coyote-light.png" class="h-8 mr-3" alt="Coyote Logo" />
                            <span class="self-center text-2xl font-semibold whitespace-nowrap "></span>
                        </a>
                        <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-700 sm:mb-0">
                        <li>
                                <a href="https://coyote-app.vercel.app" class="mr-4 hover:underline md:mr-6">Get Started</a>
                            </li>
                            <li>
                                <a href="#features" class="mr-4 hover:underline md:mr-6 ">Features</a>
                            </li>
                            <li>
                                <a href="https://twitter.com/yuvanarvind" class="hover:underline">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
                    <span class="block text-sm text-gray-700 sm:text-center">© 2023 <a href="#home" class="hover:underline">Coyote</a>. All Rights Reserved.</span>
                </div>
            </footer>
    )
}