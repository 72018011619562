import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
// import Stats from './components/Stats';
import Pricing from './components/Pricing';
import UseCase from './components/UseCase';

//Landing Page

export default function Home() {
  return (
    <section>
      <Navbar/>
        <div class="px-[10%]">
          <Hero />
          <UseCase />
          <Features />
          {/* <Stats /> */}
          <Pricing />
        </div>
      <Footer />
    </section>
  );
}
