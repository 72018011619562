import React from 'react';

export default function Pricing(){
    return(
        <div id="pricing" class="overflow-hidden">


            <div class="relative py-10">
            
                <div class="max-w-[60rem] px-4 pt-10 sm:px-6 lg:px-8 lg:pt-14 mx-auto">
                    <div class="max-w-2xl mx-auto text-center mb-10">
                        <h2 class="text-3xl  text-gray-900 leading-tight font-bold md:text-3xl md:leading-tight lg:text-4xl lg:leading-tight">Simple, transparent pricing</h2>
                        <p class="mt-2 lg:text-lg text-gray-800 ">Whatever your status, our offers evolve according to your needs.</p>
                    </div>
                </div>
                <stripe-pricing-table pricing-table-id="prctbl_1NtngnSEjnSGufjw0og9nW0t"
                publishable-key="pk_live_51NtnM9SEjnSGufjwjSQZqkGmih1NlI1HQgkt1ZY7K0yVHQqbGuWNL9NTR4L2EuHA0mV5NTObBltI0gobpWmCRVXB00dgFmIPt3">
                </stripe-pricing-table>
            </div>

                {/*  Trusted By section

                <div class="w-2/3 sm:w-1/2 lg:w-1/3 mx-auto text-center mt-10 md:mt-14 mb-6 lg:mt-24">
                    <h2 class="text-gray-600">Trusted by Open Source, enterprise, and more than 99,000 of you</h2>
                </div>
                 
            */}
        </div>
    )
}