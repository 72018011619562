import React, {useState} from 'react';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

  return (
    
    <nav className="z-50 flex items-center justify-between flex-wrap p-5 fixed top-0 w-full backdrop-filter backdrop-blur-lg bg-opacity-30 shadow-sm">
      {/* Sticky Nav - fixed top-0 w-full bg-white shadow-sm */}
      <div className="flex items-center lg:justify-start text-white">
        <a href="#home">    
          <img
            src="coyote-light.png"
            className="w-100 h-10 mr-2"
            alt="Logo"
          />
        </a>
      </div>
      {/* Hamburger Menu */}
      <div className="block lg:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400">
          <svg
            className={`fill-current h-5 w-5 ${isOpen ? 'hidden' : 'block'}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
          <svg
            className={`fill-current h-5 w-5 ${isOpen ? 'block' : 'hidden'}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        </button>
      </div>
      <div
        className={`w-full block flex-grow justfy-between lg:flex lg:items-center lg:w-auto ${
          isOpen ? 'block' : 'hidden'
        }`}>
        <div className="text-lg lg:flex lg:flex-grow gap-x-24 justify-center">
          <a
            href="#product"
            className="block mt-4 lg:inline-block lg:mt-0 font-semibold leading-6 hover:text-gray-500 text-gray-900 ml-8 lg:ml-0">
            Product
          </a>
          <a
            href="#features"
            className="block mt-4 lg:inline-block lg:mt-0 font-semibold leading-6 hover:text-gray-500 text-gray-900 ml-8 lg:ml-0">
            Features
          </a>
          <a
            href="#pricing"
            className="block mt-4 lg:inline-block lg:mt-0 font-semibold leading-6 hover:text-gray-500 text-gray-900 ml-8 lg:ml-0">
            Pricing
          </a>
        </div>
 
          <a
            class="lg:ml-4 inline-flex mt-4 lg:mt-0 justify-center items-center gap-x-3 text-center bg-white  hover:bg-red-500 hover:text-white border border-red-500 text-gray-900 text-md font-medium rounded-full py-3 px-4"
            href="https://coyote-app.vercel.app/">
            Get Started
          </a>
      </div>
    </nav>
  );
}
