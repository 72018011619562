import React from 'react';

export default function Hero() {
  return (
    <div id="home" class="font-montserrat relative">
      <img
        src="concentric-circles.png"
        className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
        alt="concentric-circles"/>
      {/* Content of Hero */}
      <div class="relative overflow-hidden ">
        <div class="max-w-[85rem] mx-auto h-screen flex flex-col justify-center items-center pb-6">
          <div class="flex justify-center">
            <a class="inline-flex items-center gap-x-2 bg-white border border-gray-200 text-xs text-gray-600 p-2 px-3 rounded-full transition hover:border-gray-400" href="#usecase">
              Learn how to use
              <span class="flex items-center gap-x-1">
                <span class="border-l border-gray-200 text-red-500 pl-2">Explore</span>
                <svg class="w-2.5 h-2.5 text-red-500" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </span>
            </a>
          </div>

          <div class="mt-5 max-w-2xl text-center mx-auto">
            <h1 class="block backdrop-filter backdrop-blur font-bold text-gray-900 text-4xl md:text-5xl lg:text-6xl">
              Monitor the <span class="text-red-500">internet</span>
            </h1>
          </div>

          <div class="mt-5 w-3/4 text-center mx-auto">
            <p class="text-lg backdrop-filter backdrop-blur text-gray-500">Coyote is an intelligent and personalised notification service that monitors the web based on your interest. Enter your favourite topics and stay updated!</p>
          </div>

          <div class="mt-8 grid gap-3 w-full sm:inline-flex sm:justify-center">
            <a class="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-red-400 to-red-600 hover:from-red-600 hover:to-red-500 border border-transparent text-white text-md font-medium rounded-full py-3 px-4" href="https://coyote-app.vercel.app/">
              Get Started
            </a>
          </div>
          {/* <div class="mt-8 grid gap-3 w-full sm:inline-flex sm:justify-center">
            <iframe class="h-10 w-auto" src="https://lottie.host/?file=fc9f41d9-f82d-46f1-834c-fcb5669f755a/dPdOMIQRX7.json"></iframe>
          </div> */}
        </div>
      </div>

    
    </div>
  );
}