import React from 'react';

export default function UseCase() {
  return (
            <div id="product" class="bg-white lg:py-14 py-10">
                <div  class="mx-auto max-w-7xl">
                    <div class="mx-auto max-w-2xl lg:text-center">
                    <h2 class="text-base font-semibold leading-7 lg:pt-2 text-red-600">Inbox Notification</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Everything you need to monitor</p>
                    <p class="mt-6 text-lg leading-8 text-gray-600">Coyote lets users track keywords, phrases, or topics online. It sends email alerts when new content related to their interests is found, such as news, blogs, and socials.</p>
                    </div>

                    <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                   
                        <div class="mx-auto max-w-3xl flex justify-between flex-col sm:flex-row gap-14 lg:gap-8">
                            
                        <div class="col-span-6 sm:col-span-4 text-center">
                            <svg class="mx-auto h-auto w-7 md:w-9 text-gray-800" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                            </svg>
                            <div class="mt-2 sm:mt-6">
                                <h3 class="text-lg font-semibold text-gray-800 ">
                                Personalized
                                </h3>
                            </div>
                            </div>
                            
                            <div class="col-span-6 sm:col-span-4 text-center">
                            <svg class="mx-auto h-auto w-7 md:w-9 text-gray-800" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M9.465 10H12a2 2 0 1 1 0 4H9.465c.34-.588.535-1.271.535-2 0-.729-.195-1.412-.535-2z"/>
                                <path d="M6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 1a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm.535-10a3.975 3.975 0 0 1-.409-1H4a1 1 0 0 1 0-2h2.126c.091-.355.23-.69.41-1H4a2 2 0 1 0 0 4h2.535z"/>
                                <path d="M14 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"/>
                            </svg>
                            <div class="mt-2 sm:mt-6">
                                <h3 class="text-lg font-semibold text-gray-800">
                                Customizable
                                </h3>
                            </div>
                            </div>
                            
                            <div class="col-span-6 col-start-4 sm:col-span-4 text-center">
                            <svg class="mx-auto h-auto w-7 md:w-9 text-gray-800 " xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z"/>
                                <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                            <div id="usecase" class="mt-2  sm:mt-6">
                                <h3 class="text-lg font-semibold text-gray-800">
                                24/7 Support
                                </h3>
                            </div>
                        </div>
                        
                    </div>
                   
                </div>
                {/* I've added the id="features" in the previous section as the sticky navbar is convering the title text */}

                <div class="mx-auto mt-6 max-w-2xl lg:max-w-4xl">

                    <p class="pb-20 pt-8 mx-auto max-w-2xl lg:text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Use-cases of Coyote</p>

                    <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        <div class="relative pl-16">
                        <dt class="text-base font-semibold leading-7 text-gray-900">
                            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-500">
                            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                            </svg>
                            </div>
                            Gathering Domain Knowledge
                        </dt>
                        <dd class="mt-2 text-base leading-7 text-gray-600">Eg. If you're building in the 'SaaS billing' space, key in interests as "usage-based pricing", "Saas pricing models" etc</dd>
                        </div>
                        <div class="relative pl-16">
                            <dt class="text-base font-semibold leading-7 text-gray-900">
                                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-500">
                                <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                </svg>
                                </div>
                                Competitor Analysis
                            </dt>
                            <dd class="mt-2 text-base leading-7 text-gray-600">Key in interests as your competitor companies, and the industry moat to keep track of new entrants.</dd>
                        </div>
                        <div class="relative pl-16">
                            <dt class="text-base font-semibold leading-7 text-gray-900">
                                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-500">
                                <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                                </div>
                                Aid in Content Generation
                            </dt>
                            <dd class="mt-2 text-base leading-7 text-gray-600">Keying in relevant interests will notify you of the latest trends and happenings, which will help you generate content for your company.</dd>
                        </div>
                            <div class="relative pl-16">
                                <dt class="text-base font-semibold leading-7 text-gray-900">
                                    <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-500">
                                    <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                                    </svg>
                                    </div> 
                                    Sentiment Analysis
                                </dt>
                                <dd class="mt-2 text-base leading-7 text-gray-600">Provides the current state of the industry as Coyote notifies you of what the world is saying on different social platforms.</dd>
                            </div>
                    </dl>
                </div>
            </div>
        </div>
)
}